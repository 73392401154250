import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { StaticImage } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';
import Timeline from '../components/Timeline';
import PageWrapper from '../components/PageWrapper';
import TeamProfiles from '../components/about-us/TeamProfiles';
import ContactForm from '../components/contact-form/ContactAboutUs';
import AboutUsBanner from '../components/about-us/Banner';
import { applyFormId } from '../constants';
import { getPageTitle } from '../utils';

const ourStoryImgMobile = 'our-story-img';
const aboutUsImgPrefix = '../images/about-us/';
const pageTitle = getPageTitle('About Us');

const OurStoryImages = () => (
  <>
    <StaticImage
      className={ourStoryImgMobile}
      src={`${aboutUsImgPrefix}/exabytegames-gaming.png`}
      alt=""
    />
    <StaticImage
      className={ourStoryImgMobile}
      src={`${aboutUsImgPrefix}/ewan-sign.png`}
      alt=""
    />
    <StaticImage
      className={ourStoryImgMobile}
      src={`${aboutUsImgPrefix}/harriett-creating.png`}
      alt=""
    />
  </>
);

const IndexPage: React.FC = () => (
  <PageWrapper>
    <Helmet>
      <title>{pageTitle}</title>
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content="Find out about us." />
    </Helmet>
    <AboutUsBanner />
    <Container fluid className="our-story-section">
      <Row>
        <Col>
          <Container>
            <Row className="our-story-content m-auto">

              <Col xs={12} lg={6}>
                <h3 className="upper">Our Story</h3>
                <p>
                  Back in 2018, Sam made the initial sketches for a management
                  game where the player protects their base from waves of
                  approaching enemies. But Sam’s coding abilities were limited.
                  So, he learned as he went along. While this inevitably resulted
                  in disorganised code, it allowed time for the game’s concept
                  and mechanics to mature. Because Sam was only able to work on
                  the game during time off from university, progress was gradual.
                  After 18 months and a period of beta testing, Final Outpost
                  was ready to release onto the App Store.
                </p>
                <p>
                  Exceeding all expectations, Final Outpost reached 100,000
                  downloads within two months. Initial traction came from a
                  small marketing campaign, which then grew from discovery on
                  the App Store. Feedback was hugely positive; the Final Outpost
                  Discord server was filling up with new members as the average
                  rating from over 2,000 reviews reached 4.4/5. Sam released a
                  number of patches during this time and began work on a
                  significant content update with his friend Ewan later in the
                  year. Final Outpost 1.1 introduced a host of new features,
                  reworked mechanics, and UI improvements.
                </p>
                <p>
                  To meet rapidly accelerating demand for an Android version of
                  the game, Sam founded Exabyte Games with Ewan and his brother,
                  Ben. They put together a Unity development team led by Ben and
                  entirely re-engineered Final Outpost for both iOS and Android,
                  releasing Final Outpost 1.2 in August 2021. The update came
                  with dynamic lighting effects, pathfinding, new resources and
                  buildings, seasons, multi-language support, and much more.
                </p>
                <p>
                  Following the excitement of Final Outpost 1.3, which
                  introduced dynamic weather and Game Center support, the team
                  shifted gears to enhance graphics and refine the player
                  experience. Our talented graphical artist, Harriett,
                  re-imagined every character and building over the next year,
                  culminating in the release of the 1.6 update, which included
                  a completely redesigned user interface.
                </p>
                <p>
                  Driven by the constant feedback and enthusiasm of the Final
                  Outpost community, Sam and Ben decided to go full-time on the
                  project in late 2023 so they could continue expanding Final
                  Outpost. It is now 7 years since Sam started the project and
                  the Exabyte Games team is now preparing for the 2025 launch
                  of Final Outpost: Definitive Edition, which is coming to PC,
                  Mac, Linux, Android and iOS.
                </p>
                <div className="d-lg-none">
                  <OurStoryImages />
                </div>
              </Col>
              <Col className="d-none d-lg-block" lg={6}>
                <div className="our-story-desktop-right-half">
                  <OurStoryImages />
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>

    <Container fluid className="timeline-section-container d-block d-lg-none">
      <Row>
        <Col>
          <Container>
            <Row>
              <Col>
                <h2 className="upper d-lg-none">Timeline</h2>
                <Timeline />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>

    <Container
      fluid
      className="team-profiles-section-container d-block d-lg-none"
    >
      <Row>
        <Col>
          <Container>
            <Row>
              <Col>
                <TeamProfiles />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>

    <Container
      fluid
      className="team-profiles-section-container d-none d-lg-block"
    >
      <Row>
        <Col>
          <Container>
            <Row>
              <Col>
                <h2 className="upper d-lg-none">Timeline</h2>
                <Timeline />
              </Col>
            </Row>
            <Row>
              <Col>
                <TeamProfiles />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>

    <Container fluid className="contact-section-container">
      <Row>
        <Col>
          <Container>
            <Row>
              <Col>
                <h2 id={applyFormId} className="upper">
                  Apply Here
                </h2>
                <p className="text-center">
                  We&apos;re always looking for motivated people to join the
                  team.
                </p>
                <ContactForm isCVFocusedForm />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  </PageWrapper>
);

export default IndexPage;
